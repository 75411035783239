
body {
    font-family: 'DM Sans', sans-serif !important;
}
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, .navbar, .brand, a, .td-name, td, button, input, select, textarea {
    font-family: 'DM Sans', sans-serif !important;
}

.header-top {
    background: #111111;
    width:100%;
    padding: 10px 0px;
    position: absolute;
    top:0px;
    left:0px;
    right:0px;
}
.header-top span {
    padding: 0px 20px;
}
.header-top span a {
    color:#ffffff;
    letter-spacing: 1px;
}
.header-top span img {
    margin-right: 14px;
}

.navbar.navbar-transparent {
    background: #ffffff !important;
}

.navbar .logo {
    padding-top: 20px;
}

.navbar.navbar-transparent .navbar-brand {
    padding: 10px 0px 6px;
}
.navbar .navbar-brand {
    padding: 14px 0px 6px;
}

.navbar .nav-item {
    padding-top: 36px;
}
.navbar.navbar-transparent .nav-item {
    padding-top: 8px;
}

.navbar.navbar-transparent .logo {
    padding-top: 0px;
}

.nav-item {
    padding: 0px 20px;
}
.nav-item a {
    color:#111111;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-top: 10px;
}
.nav-item a.active {
    color:#ff0707;
    border-top: 2px solid #ff0707;
}
.nav-item a:hover {
    color:#ff0707;
}
.nav-cont {
    padding-top: 20px;
}

.page-header {
    background: url('../img/banner-bg.jpg') center;
    /*min-height: 80vh !important;
    max-height: 700px;*/
    min-height: auto !important;
    margin-top: 138px;
}
.page-header .filter::after {
    background-color: transparent;
}
.slider-wrapper {
    position: relative;
    background: #ffffff;
    padding:10px;
    margin-top: 20px;
    top:10%;
}
.slider-wrapper .col {
    padding: 20px !important;
    border-radius:0px;
    background: #ffffff;
}


.footer {
    background: #111111;
}
.welcome .left {
    padding: 30px 20px 20px;
}
.welcome .right {
    padding: 60px 20px 20px;
}
.photo-frame {
    /*-webkit-box-shadow: 10px 14px 0px -1px rgba(235,6,6,1);
    -moz-box-shadow: 10px 14px 0px -1px rgba(235,6,6,1);
    box-shadow: 10px 14px 0px -1px rgba(235,6,6,1);*/
}
.photo-frame {
    background: #fff;
    padding: 10px;
    border:5px solid #dbdbdb;
    /*-webkit-box-shadow: 15px 15px 0px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 15px 15px 0px 0px rgba(0,0,0,0.75);
    box-shadow: 15px 15px 0px 0px rgba(0,0,0,0.75); */
}
.photo-frame p {
    color: #ff0707;
    font-size: 16px;
    font-style: italic;
    padding-top: 12px;
}

.welcome .left p {
    padding-right: 30px;
    line-height: 36px;
    font-size: 15px;
    color: #0f0840;
    padding-bottom: 20px;
}

.welcome h2 {
    background: url('../img/title-border.jpg') left 6px no-repeat;
    background-size: 30px;
    padding-left: 48px;
    font-size: 28px;
    color:#0f0840;
    font-weight: bold;
    letter-spacing: 1px;
    margin-bottom: 20px;
}
.welcome h2 span {
    font-size: 20px;
    color: #eb0606;
    display: block;
    font-weight: normal;
}
.welcome h3 {
    color:#0f0840;
}
.welcome ul {
    padding: 20px;
    line-height: 32px;
    font-size: 16px;
    list-style-type: square;
}
.welcome ul li {
    color:#eb0606;
}
.ourservice {
    background: #e7e6ec;
    padding: 40px 20px 60px;
}
.ourservice h2 {
    font-size: 30px;
    margin: 10px 0px 40px;
    color:#0f0840;
}
.ourservice h2 span {
    color:#eb0606;
}
.ourservice .col {
    background: #ffffff;
    border-radius: 10px;
    padding: 15px;
    padding: 30px 20px;
    text-align: center;
    -webkit-box-shadow: 0px 28px 57px -34px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 28px 57px -34px rgba(0,0,0,0.75);
    box-shadow: 0px 28px 57px -34px rgba(0,0,0,0.75);
}
.serv-icon {
    width:90px;
    height:90px;
    border-radius:90px;
    background: #e7e6ec;
}
.ourservice .col h4 {
    margin: 20px 0px 15px;
    color:#0f0840;
    font-size: 20px;
}
.ourservice .col p {
    font-size: 14px;
    color:#111111;
}
.serv-icon img {
    position: relative;
    top:12px;
}
.btn-primary {
    background:#fff;
    padding: 8px 20px;
    border:2px solid #cacaca;
    border-radius:0px;
    margin-top: 20px;
    letter-spacing: 1px;
    color: #eb0606;
}
.btn-primary:hover {
    background:#eb0606;
    color: #fff;
    border:2px solid #eb0606;

}
.consulting-form {
    text-align: center;
    background: #0f0840;
    padding: 20px 10px 30px;
    margin-top: 40px;
    position: relative;
}
.consulting-form h5 {
    font-size: 18px;
    color: #fff;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.consulting-form h3 {
    font-size: 22px;
    color: #ff0707;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 10px 0px 20px;
}
.consulting-form .form-wrap {
    background: white;
    padding: 4px;
    padding-left: 6px;
}
.consulting-form .form-wrap .row {
    margin: 0px !important;
}
.consulting-form  .form-wrap .form-control {
    border-radius:0px;
    border-right: none;
    border-color:#a1a1a1;
    height:41px;
}
.consulting-form .form-wrap button {
    color: #fff;
    background: #ff0707;
    border:2px solid #ff0707;
    border-radius: 0px;
}
.consulting-form .form-wrap button:hover {
    background: #0f0840;
    border:2px solid #0f0840;
}
.loader-wrap {
    position: absolute;
    z-index: 2;
    left:50%;
    top:50%;
}
.loader {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #ffffff;
  background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #ffffff;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.loader:after {
  background: #0dc5c1;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.our-customer {
    padding: 30px 30px 40px;
    text-align: left;
}
.our-customer h2 {
    font-size: 30px;
    margin: 10px 0px 20px;
    color:#0f0840;
}
.our-customer h2 span {
    color:#eb0606;
}
.our-customer .img-wrap {
    width:200px;
    margin: 30px 15px;
    background: #fff;
    border:2px solid #e9e9e9;
    padding: 4px;
}
.slick-initialized .slick-slide {
    width:200px !important;
    margin-left: 20px;
    margin-right: 20px;
}
.footer {
    padding-top:30px;
}

.ft-col {
    padding: 20px;
}
.ft-col h3 {
    margin-top: 10px;
    color:#ff0707;
    font-size: 18px;
    padding-left: 40px;
}
.ft-col p {
    color: #d8d8d8;
    padding-top: 20px;
    font-size: 14px;
    line-height:24px;
}
.ft-col ul {
    padding: 20px 0 0 40px;
    margin: 0px;
}
.ft-col ul li {
    line-height: 24px;
    margin-left: 15px;
}
.ft-col ul li a {
    color:#fff;
    line-height: 30px;
}
.ft-col ul li a:hover {
    color:#ff0707;
}
.ft-border {
    border-top:1px solid #5b5b5b;
}

.ft-con-cal a {
    color: #ff0707;
}
.ft-con-cal h3 {
    margin-bottom: 20px;
}
.ft-con-cal div {
    padding-left: 40px;
    padding-bottom: 18px;
    color:#fff;
    font-size: 14px;
    margin-left: 40px;
}
.ft-con-cal div img {
    margin-right: 10px;
}
.footer nav > ul > li {
    padding:5px 15px !important;
    margin:5px 15px !important;
}
.footer .credits, .footer-nav {
    line-height:36px;
    color:#A49E9E;
}
.ic-address {
    background: url('../img/ic-location.png') no-repeat 5px top;
    background-size: 28px;
}
.ic-address p {
    padding-top: 0px;
}
.ic-mail {
    background: url('../img/ic-mail.png') no-repeat left top;
    background-size: 32px;
}
.ic-mail p {
    padding-top: 0px;
}
.ic-contact {
    background: url('../img/ic-phone.png') no-repeat 5px top;
    background-size: 28px;
}
.ic-contact p {
    padding-top: 0px;
}
.copyright a {
    color: #ff0707;
}
.footer .heart {
    color: #ff0707;
}
.t-left {
    padding: 30px;
    text-align: right;
}
.t-left h2 {
    color:#fff;
    padding: 60px 0 0 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 52px;
    font-size: 30px;
}
.t-left h2 span {
    font-size: 40px;
    color: #ff1313;
    font-weight:bold;
}

.t-right {
    border-top:5px solid #ff0707;
    border-bottom:5px solid #ff0707;
    padding-right: 0px;
    padding-left: 0px;
    /*padding-top: 40px;
    padding-bottom: 40px;*/

}
.carousel-wrap {
    padding: 30px 20px 30px 10px !important;
    background: #fdfdfd;
}

.page-carousel {
    width:90%;
}
.carousel-wrap .card {
    box-shadow: none !important;
}
.card .carousel .carousel-indicators {
    bottom:-30px;
}

.carousel-inner > .carousel-item > img, .carousel-inner > .carousel-item > a > img {
    box-shadow: none !important;
    border-radius: none !important;
}

.input-group-prepend + .form-control {
    border-left: 1px solid;
}
.form-wrap span.error {
    font-size: 11px;
    display: block;
    text-align: left;
    color:red;
}
span.single {
    display: inline-block !important;
    font-size: 28px !important;
}

.contact-wrap h2 {
    color:#0f0840;
    font-size:30px;
}
.contact-wrap h2 span {
    color:#eb0606;
}
.contact-wrap p {
    font-size:16px;
}
.form-control {
    border-radius: 0px;
}
.contact-wrap a {
    color:#eb0606;
}
.error {
    font-size: 11px;
    display: block;
    text-align: left;
    color:red;
}

.loading {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid rgba(235,6,6, 0.5);
  border-radius: 50%;
  border-top-color:#eb0606;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  position: fixed;
  z-index: 1;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.map-wrap {
    margin-bottom: -5px;
}
p.blue {
    color:#0f0840;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
    background:#0f0840 !important;
    border-color:#0f0840 !important;
}
.btn:active, .btn:focus {
    background:#0f0840 !important;
    border-color:#0f0840 !important;
}



@media (min-width: 320px) and (max-width: 767px) {
    .nav-cont {
        padding-top: 40px;
    }
    .navbar .navbar-collapse.show .navbar-nav .nav-item {
        padding-left: 0px;
    }
    .navbar .navbar-collapse.show .navbar-nav .nav-item a {
        color: #fff;
        opacity: 1;
    }
    .navbar .navbar-collapse:after {
        background: #0f0840;
    }
    .navbar .logo {
        padding-left: 20px;
    }
    .consulting-form .form-wrap .form-control {
        border-right:1px solid;
    }
    .consulting-form .form-wrap button {
        margin-top: 4px;
    }
    .ourservice .col {
        margin-bottom: 20px;
    }
    .our-customer {
        padding: 20px;
    }
    .slick-slider{
        height:160px;
    }
    .ft-col {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1280px;
    }
}
